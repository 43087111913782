<template>
<!-- 保修期定义 -->
  <div class="warrty">
    <div class="exportData">
      <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
      <el-button class="textBtn" @click="handlePreview" type="text">导出</el-button>
    </div>
    <div class="tableList">
      <div class="table">
        <el-table :data="tableData" stripe style="width: 100%">
          <!-- <el-table-column type="selection"  width="55"></el-table-column> -->
          <el-table-column prop="supperCode" label="供应商编码" width="100px" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="supperName" label="供应商名称" :show-overflow-tooltip="true" width=""></el-table-column>
          <el-table-column prop="purchaseCode" label="采购品类代码" width="" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="purchaseName" label="采购品类名称" width="" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="guarantee" label="保修期(月)" width="">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.guarantee" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template #default="scope">
              <el-button v-if="scope.row.edStart === 1" type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-button v-if="scope.row.edStart === 2" type="text" size="mini" @click="preservation(scope.row)">保存</el-button>
              <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tablepage">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size = pageSize
          layout="total,  prev, pager, next, jumper"
          :total = total>
          </el-pagination>
        </div>
      </div>
    </div>

    <dialog-box ref="AddNewData" componentName="WarrantyDialog" dialogWidth="50%" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>
  </div>
</template>

<script>
import { exportForm, request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { MIME } from '@/assets/js/mime.js'
import qs from 'qs'
export default {
  name: 'Warranty',
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      categoryData: {},
      categoryDataCopy: {},
      suplierData: {},
      suplierDataCopy: {},
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      AddcheckDialogShow: false,
      warrCheckData: [],
      tableData: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取保修期定义列表
    getDataList (data) {
      const obj = {
        ...data
      }
      request('/api/evaluation/reworkGuarantee/queryAll?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
          this.tableData.forEach(item => {
            item.edStart = 1
            item.showDisabled = true
          })
        }
      })
    },

    // 新增弹框
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      newsArr.edStart = 1
      newsArr.showDisabled = true
      if (!newsArr.supplierCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择供应商'
        })
        return false
      }
      if (!newsArr.cgModel) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择采购品类'
        })
        return false
      }
      if (!newsArr.ghqData) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入保修期'
        })
        return false
      }

      const obj = {
        supperCode: newsArr.supplierCode,
        supperName: newsArr.supplier,
        purchaseCode: newsArr.cgModel,
        purchaseName: newsArr.cgModelName,
        guarantee: newsArr.ghqData
      }
      request('/api/evaluation/reworkGuarantee/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
      })
      this.AddcheckDialogShow = false
    },

    // 保存
    preservation (row) {
      const obj = {
        ...row
      }
      request('/api/evaluation/reworkGuarantee/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          row.edStart = 1
          row.showDisabled = true
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          this.getDataList()
        }
      })
    },
    // 编辑
    edit (row) {
      row.edStart = 2
      row.showDisabled = false
    },
    // 删除
    eliminate (row) {
      request('/api/evaluation/reworkGuarantee/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },

    // 导出
    handlePreview () {
      var file = { name: '保修期数据.xls' }
      exportForm('/api/evaluation/reworkGuarantee/poi?' + qs.stringify(this.searchdata), 'POST').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },

    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.getDataList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.getDataList()
    }
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.warrty{
  .exportData{
    .el-button{
      font-size: 16px;
    }
  }
  .tableList{
    margin-top: 0;
    padding: 0;
    .table{
      .el-table td{
        padding: 4px 0;
      }
    }
    :deep(.el-input__inner){
      height: 34px;
      line-height: 34px;
    }
  }
  .dialogBox{
    .table{
      .el-table td{
        padding: 9px 0;
      }
    }
    .tableListData{
      padding: 0 30px;
    }
  }
  .is-disabled{
    :deep(.el-input__inner){
      background: none;
      border: none;
      color: #606266;
    }
  }
}
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  right: 35px;
  top: 8px;
  font-size: 16px;
 }
}

</style>
